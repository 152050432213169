import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'accountBilling',
  getPromise: async ({ coreClient, store }) => {
    try {
      const { customerId } = store.selectAccount();
      if (!customerId) {
        return {
          list: [],
        };
      }
      const { list } = await coreClient.fetchAccountInvoice(customerId);
      /* convert to an array of objects with key is customerId */
      return {
        customerId /* attach to result to detect which response corresponding to this list */,
        list,
      };
    } catch (err) {
      return Promise.reject(new Error('Failed to fetch'));
    }
  },
  persist: false,
});
bundle.reactShouldFetchAccountBilling = createSelector(
  'selectAccountBillingIsLoading',
  'selectAccountBilling',
  'selectAccount',
  'selectAccountBillingShouldUpdate',
  (isLoading, accountBilling, account, shouldUpdate) => {
    const actionObj = { actionCreator: 'doFetchAccountBilling' };
    if (account && !isLoading) {
      /* go directly in to route */
      if (shouldUpdate) {
        return actionObj;
      }
      /* when changing route, and after accountBilling is fetched */
      if (accountBilling && accountBilling.customerId !== account.customerId) {
        return actionObj;
      }
    }
    return false;
  },
);
bundle.doFetchPdfLink = invoiceId => async ({ dispatch, coreClient }) => {
  try {
    const result = await coreClient.fetchBillingPdfLink(invoiceId);
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};
/* update day remaining in billing tab */
bundle.doUpdateDayRemaining = (accountId, dayRemaining) => async ({
  dispatch,
  coreClient,
}) => {
  try {
    const result = await coreClient.updateDayRemaining(accountId, dayRemaining);
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};
export default bundle;
