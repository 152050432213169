import qs from 'query-string';
import dayjs from 'dayjs';

export const getSortOrderFromQuery = field => {
  const query = qs.parse(window.location.search);
  return !query[field] ? '' : query[field] === 'ASC' ? 'ascend' : 'descend';
};

// compare the sort inside state bundle with the one got from url query to determine refetch
export const compareSortField = (sortState, sortQuery) => {
  const keyState = Object.keys(sortState)[0];
  const valueState = Object.values(sortState)[0];

  const queryEntries = Object.entries(sortQuery);
  if (!queryEntries.length && !keyState && !valueState) return false;
  return !queryEntries.some(
    ([key, value]) => key === keyState && value === valueState,
  );
};

export const handleTableOnChange = doUpdateQueryCallBack => (
  _pagination,
  _filter,
  sorter,
) => {
  let query = qs.parse(window.location.search);
  const { order, columnKey } = sorter;
  query = { page: query.page };

  if (order) {
    query[columnKey] = order === 'ascend' ? 'ASC' : 'DESC';
  }

  doUpdateQueryCallBack &&
    doUpdateQueryCallBack({ ...query }, { replace: false });
};

// add createdOn field if not exist with type timestamp milliseconds
export const convertDataToIncludeCreatedOn = data => {
  const { createdAt, createdOn } = data;
  if (!createdOn) return { ...data, createdOn: dayjs(createdAt).valueOf() };
  return data;
};
