import React, { memo } from 'react';
import { connect } from 'redux-bundler-react';
import navHelper from 'internal-nav-helper';

import { Layout, Menu, Badge } from 'antd';
import { ReactComponent as SvgLogoDark } from './svg/logo-dark.svg';

const { Sider } = Layout;

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const Sidebar = ({ doUpdateUrl, totals, pathname, ...rest }) => {
  const MemoizedBadge = memo(
    props => {
      return <Badge {...props} />;
    },
    (prevProps, nextProps) => prevProps.count === nextProps.count,
  );
  let totalAccount = 0;
  let totalUser = 0;
  let totalBase = 0;
  if (totals) {
    totalAccount = totals.totalAccount;
    totalUser = totals.totalUser;
    totalBase = totals.totalBase;
  }

  return (
    <Sider
      style={{
        background: '#fff',
        overflow: 'auto',
        height: '100%',
        position: 'fixed',
        left: 0,
        width: 'var(--siderbar-width)',
      }}
      onClick={navHelper(doUpdateUrl)}
    >
      <div className="logo h-16 p-3">
        <a href="/">
          <SvgLogoDark />
        </a>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={[pathname]}
        selectedKeys={pathname}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item style={menuItemStyle} key="/accounts">
          <a href="/accounts">Accounts</a>
          <MemoizedBadge
            showZero
            style={{ backgroundColor: '#4abfb4' }}
            count={totalAccount}
            title={'Total number of accounts'}
            overflowCount={1000}
          />
        </Menu.Item>
        <Menu.Item style={menuItemStyle} key="/users">
          <a href="/users">Users</a>
          <MemoizedBadge
            showZero
            style={{ backgroundColor: '#4abfb4' }}
            count={totalUser}
            title={'Total number of users'}
            overflowCount={1000}
          />
        </Menu.Item>
        <Menu.Item style={menuItemStyle} key="/bases">
          <a href="/bases">Bases</a>
          <MemoizedBadge
            showZero
            style={{ backgroundColor: '#4abfb4' }}
            count={totalBase}
            title={'Total number of bases'}
            overflowCount={1000}
          />
        </Menu.Item>
        <Menu.Item style={menuItemStyle} key="/feature-override-groups">
          <a href="/feature-override-groups">Feature Override Group</a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default connect(
  'selectTotals',
  'selectRoute',
  'selectPathname',
  'doUpdateUrl',
  Sidebar,
);
