import { Auth } from 'alphakit';
import jwtDecode from 'jwt-decode';
import { client as coreClient } from 'services/core';

let AuthSingleton = null;

const AUTH_API_HOST =
  process.env.REACT_APP_AUTH_HOST || 'http://localhost:8082';

class AuthSvc {
  constructor({ baseURL = AUTH_API_HOST, storagePrefix = 'base_' }) {
    this.akAuth = new Auth({ baseURL, storagePrefix });
    this.baseURL = baseURL;
    this.uniqueClientId = this.akAuth.clientId;
    this.currentAccountId = '';
  }

  signInLocal = async (username, password) => {
    const result = await this.akAuth.signInLocal(username, password);
    return result;
  };

  signOut = async () => {
    const result = await this.akAuth.signOut();
    return result;
  };

  refreshToken = async () => {
    const result = await this.akAuth.refresh();
    return result;
  };

  getAccessToken = () => this.akAuth.accessToken;
  setAccessToken = accessToken => {
    this.akAuth.accessToken = accessToken;
  };

  getGoogleAuthHref = (additionalQueryParams = '') => {
    return `${this.baseURL}/auth/google?clientId=${this.uniqueClientId ||
      this.akAuth.clientId}&${additionalQueryParams}`;
  };

  getUserId = () => this.akAuth.userId;
  setUserId = userId => {
    this.akAuth.userId = userId;
  };

  getRefreshToken = () => this.akAuth.refreshToken;
  setRefreshToken = refreshToken => {
    this.akAuth.refreshToken = refreshToken;
  };

  getUserId = () => {
    try {
      const decoded = jwtDecode(this.akAuth.refreshToken);
      const { uid: userId } = decoded;
      return { userId };
    } catch (e) {
      console.error('Error parsing token');
      return {};
    }
  };

  getResetPasswordLink = async ({ email }) => {
    try {
      const { link } = await coreClient.fetch(
        'auth/get-reset-password-link',
        'POST',
        {
          body: { email },
          prefixUrl: this.baseURL,
        },
      );
      return link;
    } catch (e) {
      console.log(e);
    }
  };
}

const initAuthSingleton = authSvc => {
  AuthSingleton = authSvc;
};
const getAuthSingleton = () => AuthSingleton;

export { AuthSvc, getAuthSingleton, initAuthSingleton };
