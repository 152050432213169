import { createAsyncActions } from './utils/asyncActions';

const actionObject = createAsyncActions('USER_UNLINK');

export default {
  name: 'userUnlink',
  reducer: (
    state = { success: false, error: false, isFetching: false },
    { type, payload },
  ) => {
    switch (type) {
      case actionObject.STARTED:
        return {
          sucess: false,
          error: false,
          isFetching: true,
        };

      case actionObject.FINISHED:
        return {
          sucess: true,
          error: false,
          isFetching: false,
        };

      case actionObject.FAILED:
        return {
          sucess: false,
          error: true,
          isFetching: false,
        };

      default:
        return state;
    }
  },
  doUserUnlink: (userId, linkedAccountId) => async ({
    dispatch,
    store,
    coreClient,
  }) => {
    try {
      dispatch({ type: actionObject.STARTED });
      await coreClient.unlinkLinkedAccountByUserId(userId, linkedAccountId);
      dispatch({ type: actionObject.FINISHED });
      return 'Succeed';
    } catch (err) {
      dispatch({ type: actionObject.FAILED });
      return Promise.reject(new Error('Failed to delete'));
    }
  },
  selectUserUnlink: state => state.userUnlink,
};
