import { createSelector, createAsyncResourceBundle } from 'redux-bundler';
import dlv from 'dlv';
import { compareSortField } from 'utils/misc';

const bundle = createAsyncResourceBundle({
  name: 'allAccounts',
  actionBaseType: 'ALL_ACCOUNTS',
  getPromise: ({ coreClient, store }) => {
    const { page, ...rest } = store.selectQueryObject();
    return coreClient.listAccount({ ...rest, page, limit: 10 });
  },
  persist: false,
});

bundle.reactShouldFetchAllAccounts = createSelector(
  'selectAllAccounts',
  'selectAllAccountsShouldUpdate',
  'selectAllAccountsIsLoading',
  'selectPathname',
  'selectQueryObject',
  (allAccounts, shouldUpdate, isLoading, pathname, queryObject) => {
    const actionObj = { actionCreator: 'doFetchAllAccounts' };
    const { page: pageQueryString, ...rest } = queryObject;
    const pageQuery = parseInt(pageQueryString, 10);

    const page = dlv(allAccounts, 'page', 1);
    const sort = dlv(allAccounts, 'sort', {});
    /* directly go into route */
    if (!isLoading && shouldUpdate) {
      /* fetch in accounts page */
      if (pathname === '/accounts') {
        return actionObj;
      }
    }
    if (!isLoading && !shouldUpdate) {
      if (
        (pageQuery !== page || compareSortField(sort, rest)) &&
        pathname === '/accounts'
      ) {
        return actionObj;
      }
    }
    return false;
  },
);

export default bundle;
