const initialState = { user: null, accessToken: '', refreshToken: '' };

export default {
  name: 'user',
  persistActions: ['USER_UPDATE', 'USER_UNSET'],
  getReducer: () => {
    return (state = initialState, { type, payload }) => {
      if (type === 'USER_UPDATE') {
        return Object.assign({}, state, { ...payload });
      }
      if (type === 'USER_UNSET') {
        return { ...initialState };
      }
      return state;
    };
  },
  selectUser: state => state.user,
  selectCurrentUser: state => state.user.user,
  selectAccessToken: state => state.user.accessToken,
  selectRefreshToken: state => state.user.refreshToken,
  selectIsLoggedIn: state => !!state.user.accessToken,
  doUpdateUser: data => ({ type: 'USER_UPDATE', payload: data }),
  doUpdateAccessToken: accessToken => ({ dispatch, store }) => {
    const user = store.selectUser();
    dispatch({ type: 'USER_UPDATE', payload: { ...user, accessToken } });
  },
  doUnsetUser: () => ({ type: 'USER_UNSET' }),
  doCreateAccountForUser: (
    userId,
    accountName,
    accountType,
    securityGroup,
  ) => async ({ coreClient }) => {
    try {
      const result = await coreClient.createAccountForUser(
        userId,
        accountName,
        accountType,
        securityGroup,
      );
      return result;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
