import { createAsyncActions } from './utils/asyncActions';

const actionObject = createAsyncActions('USERS_OF_ACCOUNTS');

export default {
  name: 'usersOfAccount',
  reducer: (state = { data: null, isFetching: false }, { type, payload }) => {
    switch (type) {
      case actionObject.STARTED:
        return {
          data: null,
          isFetching: true,
        };

      case actionObject.FINISHED:
        return {
          data: payload,
          isFetching: false,
        };

      case actionObject.FAILED:
        return {
          data: null,
          isFetching: false,
        };

      default:
        return state;
    }
  },
  doFetchUsersOfAccount: accountId => async ({
    dispatch,
    store,
    coreClient,
  }) => {
    try {
      dispatch({ type: actionObject.STARTED });
      const { users } = await coreClient.fetchAccountDetail(accountId);
      dispatch({ type: actionObject.FINISHED, payload: users });
      return users;
    } catch (err) {
      dispatch({ type: actionObject.FAILED });
      return Promise.reject(err);
    }
  },
  selectUsersOfAccount: state => state.usersOfAccount,
};
