import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button, Form, Input, message, Modal } from 'antd';

const AddAccountForm = ({ visible, onClose, doAddAccount, form }) => {
  const [isInProgress, setSubmissionStatus] = useState(false);

  const submitHandler = e => {
    e.preventDefault();

    form.validateFields(
      ['firstName', 'lastName', 'email', 'password', 'confirmPassword'],
      async (
        err,
        { firstName, lastName, email, password, confirmPassword },
      ) => {
        if (err) {
          message.error('Please check your input!');
          return;
        }

        if (password !== confirmPassword) {
          message.error('Please make sure that both passwords are the same');
          return;
        }

        setSubmissionStatus(true);
        try {
          await doAddAccount(email, firstName, lastName, password);
        } catch (error) {
          message.error('Could not create account. Please try again later.');
        } finally {
          setSubmissionStatus(false);
          onClose();
        }
      },
    );
  };

  return (
    <Modal
      title="Create Account"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        onSubmit={submitHandler}
      >
        <Form.Item label="First Name">
          {form.getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                type: 'string',
                message: 'Please enter a first name',
              },
            ],
          })(<Input placeholder="First Name" />)}
        </Form.Item>
        <Form.Item label="Last Name">
          {form.getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                type: 'string',
                message: 'Please enter a last name',
              },
            ],
          })(<Input placeholder="Last Name" />)}
        </Form.Item>

        <Form.Item label="Email">
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                type: 'string',
                message: 'Please enter an email',
              },
            ],
          })(<Input placeholder="Email Address" />)}
        </Form.Item>

        <Form.Item label="Password">
          {form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                type: 'string',
                message: 'Please enter a password',
              },
            ],
          })(<Input.Password placeholder="Password" />)}
        </Form.Item>
        <Form.Item label="Confirm Password">
          {form.getFieldDecorator('confirmPassword', {
            rules: [
              {
                required: true,
                type: 'string',
                message: 'Please confirm your password',
              },
            ],
          })(<Input.Password placeholder="Confirm Password" />)}
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button
            disabled={isInProgress}
            type="primary"
            htmlType="submit"
            className="mr-5"
          >
            Add Account
          </Button>
          <Button type="default" className="mr-5" onClick={onClose}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  'doAddAccount',
  Form.create({
    name: 'addAccount',
  })(AddAccountForm),
);
