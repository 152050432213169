import { hot } from 'react-hot-loader/root';
import React from 'react';
import { connect } from 'redux-bundler-react';
import { Layout } from 'antd';
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import PoweredByBase from 'assets/img/powered-by-base-wide.png';
import { Helmet } from 'react-helmet';

const { Content, Footer } = Layout;

const noSidebarRouteNames = ['Login'];

const App = ({ route: { name, component: Page }, pageTitle }) => {
  return (
    <>
      <Helmet>
        {/* fallback default title */}
        <title>{pageTitle}</title>
      </Helmet>
      <Layout className="min-h-screen h-screen">
        {noSidebarRouteNames.includes(name) ? (
          <Page />
        ) : (
          <>
            <Sidebar />
            <Layout
              css={`
                margin-left: var(--siderbar-width);
              `}
            >
              <Header />
              <Content
                css={`
                  margin: 24px 16px 0;
                  overflow: auto;
                `}
              >
                <Page />
              </Content>
              <Footer
                css={`
                  text-align: center;
                `}
              >
                <img
                  alt="Powered By Base"
                  className="h-12 max-h-full max-w-full mx-auto"
                  src={PoweredByBase}
                />
              </Footer>
            </Layout>
          </>
        )}
      </Layout>
    </>
  );
};

export default hot(connect('selectRoute', 'selectPageTitle', App));
