import { createAsyncActions } from './utils/asyncActions';
import { AuthSvc, initAuthSingleton } from 'services/auth';

const AuthSingleton = new AuthSvc({ storagePrefix: 'base_' });
initAuthSingleton(AuthSingleton);

const initialState = { success: false, error: '', countLoginFailed: 0 };
const actions = createAsyncActions('AUTH');

export default {
  name: 'auth',
  getReducer: () => {
    return (state = initialState, { type, payload }) => {
      if (type === actions.STARTED) {
        return Object.assign({}, state, payload);
      }
      if (type === actions.FINISHED) {
        return Object.assign({}, state, {
          success: true,
          error: '',
        });
      }
      if (type === actions.FAILED) {
        return Object.assign({}, state, {
          success: false,
          error: payload,
        });
      }
      return state;
    };
  },
  selectLoginStatus: state => state.login,
  selectLoginError: state => state.auth.error,
  doMarkLoginSuccess: () => ({ type: actions.FINISHED }),
  doClearLoginError: () => ({ dispatch, store }) => {
    if (store.selectLoginError()) {
      dispatch({ type: actions.FAILED, payload: '' });
    }
  },
  doSetLoginError: err => ({ dispatch, store }) =>
    dispatch({ type: actions.FAILED, payload: err }),
  doLogin: ({ code, reason, message, accessToken, refreshToken }) => async ({
    dispatch,
    store,
    coreClient,
    ssoClient,
  }) => {
    if (code === '204' || code === '200') {
      await AuthSingleton.setAccessToken(accessToken);
      await AuthSingleton.setRefreshToken(refreshToken);
      const { userId } = await AuthSingleton.getUserId();
      await AuthSingleton.setUserId(userId);
      dispatch({ type: actions.FINISHED });
      store.doUpdateUser({
        accessToken,
        refreshToken,
        user: {
          id: parseInt(userId),
        },
      });
      coreClient.setToken(accessToken);
      ssoClient.setToken(accessToken);
      // store.doUpdateUrl('/accounts');
    } else {
      store.doSetLoginError(reason || 'User cancelled');
    }
  },
  doRefreshToken: () => async ({ dispatch, store, coreClient, ssoClient }) => {
    try {
      const token = await AuthSingleton.refreshToken();
      store.doUpdateAccessToken(token);
      coreClient.setToken(token);
      ssoClient.setToken(token);
      dispatch({ type: actions.FINISHED });
      return token;
    } catch (err) {
      console.log(err);
      store.doLogout();
    }
  },
};
