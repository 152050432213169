export const allAccounts = [
  {
    id: 2,
    domain: 'gmail.com',
    userCountLimit: 1,
    status: 1,
    name: 'Minh Trần',
    accountType: 'personal',
    logo:
      'https://storage.googleapis.com/basehq-images-qa/public/98404755-4e9f-43d6-9b0f-5eadafa35eaf.png',
    url: null,
    expiresOn: '2019-08-02T01:11:15.941Z',
    isExpired: false,
    description: null,
    accountMetadata: null,
    billingPlanName: 'Community',
    billingPlanSlug: 'community',
    billingMetadata: null,
    customerId: null,
    subscriptionId: null,
    primaryBillingEmail: null,
    accountInfoId: 'fcac11f6-b281-4ac5-b22a-3bafd068fbd7',
    billingPlanDescription: null,
    planType: 'community',
    cardData: null,
    nextBillingDate: null,
    plan: {
      planQuantity: null,
      planUnitPrice: '0',
      planTotal: '0',
      planStatus: null,
      period: null,
      coupons: null,
      nextBillingDate: null,
      planType: 'community',
      cardData: null,
      canChange: true,
    },
  },
];

export const base = {
  '8b3078d6-572a-4f5f-a0d4-9339ec2792f7': {
    id: '8b3078d6-572a-4f5f-a0d4-9339ec2792f7',
    baseName: 'DF',
    firstName: 'Minh',
    lastName: ' ',
    primaryEmail: 'minh@dwarvesv.com',
    avatar: 'https://www.gravatar.com/avatar?f=y&d=mp',
    accountId: 2,
    title: null,
    targetId: null,
    status: 1,
    createdAt: '2019-06-30T08:11:37.588Z',
    updatedAt: '2019-06-30T08:11:37.588Z',
    account_id: 2,
    userBase: {
      id: 'e64b9fe5-67eb-436a-b195-3f331481bc98',
      status: 1,
      expiresAt: null,
      permissionLevel: 'creator',
      accountId: 2,
      userId: 2,
      baseId: '8b3078d6-572a-4f5f-a0d4-9339ec2792f7',
      title: null,
      createdAt: '2019-06-30T08:11:37.627Z',
      updatedAt: '2019-06-30T08:11:37.627Z',
      account_id: 2,
      user_id: 2,
    },
  },
};

export const linkedAccount = {
  '2': {
    id: 2,
    userId: 2,
    type: 'google',
    status: 1,
    identifier: '115694104288509822286',
    verification:
      'eyJjbGllbnRzIjp7IjEyNDQ3MTM2NjU5Ny10NzdwZzNvYm1paTAxMDBxbmhiYjcxM3ExaHVyZThkaC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSI6eyJhY2Nlc3NUb2tlbiI6InlhMjkuR2xzNkIzMU5CX1VYdlBoN3VFdWo5VTV6N0ZJT0V6SGNUeU4ybnVSR3BpSWpvRFo5VVhzR2dBZHNCYlZXd0NFSWlORGx4LWdPaXp3REVCNENwNmEwWmw0RW9XSjhHdVNuWU5lMkh2QkJVX3BqZ1U5aG5fOGdlYjJRcG5sVyIsInJlZnJlc2hUb2tlbiI6IjEvTlF2MDFibmg1Y0EzNVRoVWl1S0JOeUVCTTFJTFloQzlad0pmUnZiMVFXUSJ9fX0=',
    metadata: {
      profile: {
        id: '115694104288509822286',
        displayName: 'Minh Trần',
        name: { familyName: 'Trần', givenName: 'Minh' },
        emails: [{ value: 'thminhkg@gmail.com', verified: true }],
        photos: [
          {
            value:
              'https://lh5.googleusercontent.com/-NX_CViCPKiM/AAAAAAAAAAI/AAAAAAAAANU/vvz1vTf16Yo/photo.jpg',
          },
        ],
        provider: 'google',
        _raw:
          '{\n  "sub": "115694104288509822286",\n  "name": "Minh Trần",\n  "given_name": "Minh",\n  "family_name": "Trần",\n  "picture": "https://lh5.googleusercontent.com/-NX_CViCPKiM/AAAAAAAAAAI/AAAAAAAAANU/vvz1vTf16Yo/photo.jpg",\n  "email": "thminhkg@gmail.com",\n  "email_verified": true,\n  "locale": "en"\n}',
        _json: {
          sub: '115694104288509822286',
          name: 'Minh Trần',
          given_name: 'Minh',
          family_name: 'Trần',
          picture:
            'https://lh5.googleusercontent.com/-NX_CViCPKiM/AAAAAAAAAAI/AAAAAAAAANU/vvz1vTf16Yo/photo.jpg',
          email: 'thminhkg@gmail.com',
          email_verified: true,
          locale: 'en',
        },
      },
      scopes: '',
    },
    refreshToken: '1/NQv01bnh5cA35ThUiuKBNyEBM1ILYhC9ZwJfRvb1QWQ',
  },
  '3': {
    id: 3,
    userId: 2,
    type: 'google',
    status: 1,
    identifier: '109285858574708559058',
    verification:
      'eyJjbGllbnRzIjp7IjEyNDQ3MTM2NjU5Ny10NzdwZzNvYm1paTAxMDBxbmhiYjcxM3ExaHVyZThkaC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSI6eyJhY2Nlc3NUb2tlbiI6InlhMjkuR2xzNkIzMEx1dGFlZ3Z6Y2x6OUZhVkxJZkh6czIzNTQ0SmxGcmhMVzdXaEdJRzctaFRTZC1ZQ0FZekRPdnRpNjdkeG52a1RvM19GSkQtelFNUEtfal8xTUZOdVVwQVA0bVBrRV9kRVJiamQ3MjJxY0FrajdXWmtDTWxqdSIsInJlZnJlc2hUb2tlbiI6IjEvcEN1RVc0VUIzZWh6dmpPWVl6ZmxHYVVleWF0SWItTnZkaUx2LVhDRHllMCJ9fX0=',
    metadata: {
      accessToken:
        'ya29.Gls6B30Lutaegvzclz9FaVLIfHzs23544JlFrhLW7WhGIG7-hTSd-YCAYzDOvti67dxnvkTo3_FJD-zQMPK_j_1MFNuUpAP4mPkE_dERbjd722qcAkj7WZkCMlju',
      refreshToken: '1/pCuEW4UB3ehzvjOYYzflGaUeyatIb-NvdiLv-XCDye0',
      profile: {
        id: '109285858574708559058',
        displayName: 'Minh Tran',
        name: { familyName: 'Tran', givenName: 'Minh' },
        emails: [{ value: 'minh@dwarvesv.com', verified: true }],
        photos: [
          {
            value:
              'https://lh3.googleusercontent.com/-g2kKttfX-ng/AAAAAAAAAAI/AAAAAAAAHjg/PTW78RdJtdk/photo.jpg',
          },
        ],
        provider: 'google',
        _raw:
          '{\n  "sub": "109285858574708559058",\n  "name": "Minh Tran",\n  "given_name": "Minh",\n  "family_name": "Tran",\n  "picture": "https://lh3.googleusercontent.com/-g2kKttfX-ng/AAAAAAAAAAI/AAAAAAAAHjg/PTW78RdJtdk/photo.jpg",\n  "email": "minh@dwarvesv.com",\n  "email_verified": true,\n  "locale": "en",\n  "hd": "dwarvesv.com"\n}',
        _json: {
          sub: '109285858574708559058',
          name: 'Minh Tran',
          given_name: 'Minh',
          family_name: 'Tran',
          picture:
            'https://lh3.googleusercontent.com/-g2kKttfX-ng/AAAAAAAAAAI/AAAAAAAAHjg/PTW78RdJtdk/photo.jpg',
          email: 'minh@dwarvesv.com',
          email_verified: true,
          locale: 'en',
          hd: 'dwarvesv.com',
        },
      },
      scopes: '',
    },
    refreshToken: '1/pCuEW4UB3ehzvjOYYzflGaUeyatIb-NvdiLv-XCDye0',
  },
};

export const baseInfo = {
  '8b3078d6-572a-4f5f-a0d4-9339ec2792f7': {
    id: '8b3078d6-572a-4f5f-a0d4-9339ec2792f7',
    baseName: 'DF',
    firstName: 'Minh',
    lastName: ' ',
    primaryEmail: 'minh@dwarvesv.com',
    avatar: 'https://www.gravatar.com/avatar?f=y&d=mp',
    accountId: 2,
    title: null,
    targetId: null,
    status: 1,
    createdAt: '2019-06-30T08:11:37.588Z',
    updatedAt: '2019-06-30T08:11:37.588Z',
    account_id: 2,
    userBase: {
      id: 'e64b9fe5-67eb-436a-b195-3f331481bc98',
      status: 1,
      expiresAt: null,
      permissionLevel: 'creator',
      accountId: 2,
      userId: 2,
      baseId: '8b3078d6-572a-4f5f-a0d4-9339ec2792f7',
      title: null,
      createdAt: '2019-06-30T08:11:37.627Z',
      updatedAt: '2019-06-30T08:11:37.627Z',
      account_id: 2,
      user_id: 2,
    },
  },
};

export const allUsers = [
  {
    id: 1,
    displayName: 'Minh Tran',
    role: 'creator',
    email: 'minh@dwarvesv.com',
  },
];
