import dlv from 'dlv';
import ky from 'ky';
import queryString from 'query-string';
import * as allResources from 'fixture/allResources';

const getUser = id => {
  return allResources.allUsers.find(i => {
    return i.id === parseInt(id);
  });
};

const convertToJson = async resp => {
  try {
    return await resp.json();
  } catch (err) {
    return resp;
  }
};

export default class CoreService {
  setToken = value => (this.token = value);
  getToken = () => this.token || '';

  onRequestError = cb => {
    typeof cb === 'function'
      ? (this.handleRequestError = cb)
      : (this.handleRequestError = () => {});
  };

  onStatus401 = cb => {
    typeof cb === 'function'
      ? (this.handleStatus401 = cb)
      : (this.handleStatus401 = () => {});
  };

  instance = ky.extend({
    prefixUrl: process.env.REACT_APP_API_HOST + '/dashboard/',
  });

  fetch = async (path = '', method = 'GET', { body, params, ...opt } = {}) => {
    try {
      const qs = queryString.stringify(params);
      const resp = await this.instance(`${path}${qs ? '?' + qs : ''}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.getToken()}`,
        },
        method,
        json: body,
        ...opt,
      });
      /* try convert to json */
      return await convertToJson(resp);
      /* end try */
    } catch (err) {
      if (err.name === 'TypeError' && err.message === 'Failed to fetch') {
        console.warn('failed to get proper response from api server, logout');
        this.handleRequestError();
      }
      const statusCode = dlv(err, 'response.status');
      if (statusCode === 401) {
        /* try to refresh token and request again */
        await this.handleStatus401();
        const qs = queryString.stringify(params);
        const resp = await this.instance(`${path}${qs ? '?' + qs : ''}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.getToken()}`,
          },
          method,
          json: body,
          ...opt,
        });
        return convertToJson(resp);
      }
      return Promise.reject(err);
    }
  };

  listAccount(query) {
    return this.fetch(`account?${queryString.stringify(query)}`, 'GET');
  }

  listUser(query) {
    return this.fetch(`user?${queryString.stringify(query)}`, 'GET');
  }

  listBase(query) {
    return this.fetch(`base?${queryString.stringify(query)}`, 'GET');
  }

  getTotal() {
    return this.fetch('getTotal');
  }

  fetchBaseDetail(baseId) {
    return this.fetch(`base/${baseId}`);
  }

  updateBaseDetail(baseId, baseName, firstName, lastName, primaryEmail) {
    return this.fetch(`base/${baseId}/update`, 'POST', {
      body: {
        baseName,
        firstName,
        lastName,
        primaryEmail,
      },
    });
  }

  inviteUserBase(baseId, accountId, userEmail, securityGroup, inviterEmail) {
    return this.fetch(`base/${baseId}/invite`, 'POST', {
      body: {
        accountId,
        email: userEmail,
        group: securityGroup,
        senderEmail: inviterEmail,
      },
    });
  }

  /* directly add user to base */
  addUserToBase(baseId, userId, securityGroup, inviterEmail) {
    return this.fetch(`base/${baseId}/add-user`, 'PUT', {
      body: {
        userId,
        group: securityGroup,
        senderEmail: inviterEmail,
      },
    });
  }

  searchAll(query) {
    const encodedQuery = queryString.stringify({ query });
    return this.fetch(`search?${encodedQuery}`);
  }

  fetchAccountDetail(accountId) {
    return this.fetch(`account/${accountId}`);
  }

  addUserToAccount(accountId, userId, securityGroup) {
    return this.fetch(`account/${accountId}/add-user`, 'PUT', {
      body: {
        userId,
        group: securityGroup,
      },
    });
  }

  /* fetch account billing invoices */
  fetchAccountInvoice(customerId) {
    return this.fetch(`account/billing/customer/${customerId}`);
  }

  async fetchBillingPdfLink(invoiceId) {
    try {
      return await this.fetch(`account/billing/pdf/${invoiceId}`);
    } catch (err) {
      return Promise.reject(new Error('Could not fetch'));
    }
  }

  /* update remaining days in billing */
  async updateDayRemaining(accountId, dayRemaining) {
    try {
      return await this.fetch(`account/billing/dayRemaining`, 'POST', {
        body: { accountId, dayRemaining },
      });
    } catch (err) {
      return Promise.reject(new Error('Could not update'));
    }
  }

  /* transfer base to new account, and set new primary email in base model */
  transferBase(accountId, baseId, newAccountId, newUserId) {
    return this.fetch(`account/${accountId}/base/transfer-base`, 'PUT', {
      body: { accountId, baseId, newAccountId, newUserId },
    });
  }

  /* get all feature name list */
  allFeaturesList(accountId) {
    return this.fetch(`account/${accountId}/all-features`);
  }

  /* get all feature of account list */
  accountFeaturesList(accountId) {
    return this.fetch(`account/${accountId}/features`);
  }

  /* add beta feature to account */
  addAccountFeature(accountId, featureName) {
    return this.fetch(`account/${accountId}/features/${featureName}`, 'PUT');
  }

  /* delete a feature of account */
  removeAccountFeature(accountId, featureId) {
    return this.fetch(`account/${accountId}/features/${featureId}`, 'DELETE');
  }

  updateAccount(accountId, data) {
    return this.fetch(`account/${accountId}`, 'PUT', { body: data });
  }

  updateAccountInfo(accountId, data) {
    return this.fetch(`account/${accountId}/account_info`, 'PUT', {
      body: data,
    });
  }

  fetchAccountInvitations(accountId) {
    return this.fetch(`account/${accountId}/invite`, 'GET');
  }

  createAccountInvitation(accountId, data) {
    return this.fetch(`account/${accountId}/invite`, 'POST', {
      body: data,
    });
  }

  deleteAccountInvitation(accountId, id) {
    return this.fetch(`account/${accountId}/invite/${id}`, 'DELETE');
  }

  deleteUserFromAccount(accountId, userId) {
    return this.fetch(`account/${accountId}/user/${userId}`, 'DELETE');
  }

  // Permanently delete base
  deleteAccountBase(accountId, baseId) {
    return this.fetch(`account/${accountId}/base/${baseId}`, 'DELETE');
  }

  // Api to update status of workspace to in-active
  disableAccountBase(accountId, baseId) {
    return this.fetch(`account/${accountId}/base/${baseId}/disable`, 'PUT');
  }

  // Api to update status of workspace to active again
  enableAccountBase(accountId, baseId) {
    return this.fetch(`account/${accountId}/base/${baseId}/enable`, 'PUT');
  }

  permanentDeleteBase(accountId, baseId) {
    return this.fetch(`account/${accountId}/base/${baseId}`, 'DELETE');
  }

  createAccountBase(accountId, data) {
    return this.fetch(`account/${accountId}/base`, 'POST', {
      body: data,
    });
  }

  async fetchUserDetail(userId) {
    try {
      /* fetch all necessary info at once */
      const { user, linkedAccounts, accounts, bases } = await this.fetch(
        `user/${userId}`,
      );
      return {
        user, // object
        linkedAccounts, // object, need to be converted to array to use in dataSource
        accounts, // array
        bases, // array
      };
    } catch (err) {
      return Promise.reject(new Error('Could not fetch'));
    }
  }

  async unlinkLinkedAccountByUserId(userId, linkedAccountId) {
    try {
      return await this.fetch(
        `user/${userId}/linkedAccount/${linkedAccountId}`,
        'DELETE',
      );
    } catch (err) {
      return Promise.reject(new Error('Could not fetch'));
    }
  }

  /**
   *
   * @param {string} email
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} password
   * @returns {Promise<Response<unknown>>}
   */
  addAccount(email, firstName, lastName, password) {
    return this.fetch(`account/create/`, 'POST', {
      body: {
        email,
        firstName,
        lastName,
        password,
      },
    });
  }

  createAccountForUser(userId, accountName, accountType, securityGroup) {
    return this.fetch(`user/${userId}/createAccount`, 'PUT', {
      body: {
        accountName,
        accountType,
        securityGroup,
      },
    });
  }

  updateUser(userId, data) {
    const account = getUser(userId);
    if (!account) return Promise.reject(Error('Not found'));
    return Promise.resolve({ ...account, ...data });
  }

  deleteUser(userId, newAccountId, newUserId) {
    return this.fetch(`user/${userId}`, 'DELETE', {
      body: { newAccountId, newUserId },
    });
  }

  deleteAccount(accountId) {
    return this.fetch(`account/${accountId}`, 'DELETE');
  }

  updateBaseUserPermission({
    userId,
    accountId,
    baseId,
    currentPermission,
    newPermission,
  }) {
    return this.fetch(`base/${baseId}/update-permission`, 'POST', {
      body: { accountId, userId, currentPermission, newPermission },
    });
  }

  deleteUserFromBaseDetail(baseId, userId) {
    return this.fetch(`base/${baseId}/user/${userId}`, 'DELETE');
  }

  switchUserPermission(accountId, userId, securityGroup, currentSecurityGroup) {
    return this.fetch(`user/${userId}/switch-permission`, 'POST', {
      body: { accountId, userId, securityGroup, currentSecurityGroup },
    });
  }

  updateUserAccountLoginType(accountId, userId, loginType) {
    return this.fetch(`user/${userId}/loginType`, 'POST', {
      body: { accountId, userId, loginType },
    });
  }

  getOriginalFeatures() {
    return this.fetch(`original-features`, 'GET');
  }

  getFeatureOverrideGroups() {
    return this.fetch(`feature-override-groups`, 'GET');
  }

  getFeatureOverrideGroupDetails(id) {
    return this.fetch(`feature-override-groups/${id}`, 'GET');
  }

  createFeatureOverrideGroups(name) {
    return this.fetch(`feature-override-groups`, 'POST', { body: { name } });
  }

  updateFeatureOverrideGroups(id, { name, status }) {
    return this.fetch(`feature-override-groups/${id}`, 'PUT', {
      body: { name, status },
    });
  }

  deleteFeatureOverrideGroups(id) {
    return this.fetch(`feature-override-groups/${id}`, 'DELETE');
  }

  createOverrideFeature({ featureId, groupId, value }) {
    return this.fetch(`feature-override-groups/${groupId}/features`, 'POST', {
      body: { featureId, value },
    });
  }

  updateOverriddenFeature({ groupId, featureId, value }) {
    return this.fetch(
      `feature-override-groups/${groupId}/features/${featureId}`,
      'PUT',
      {
        body: { value },
      },
    );
  }

  deleteOverriddenFeature(groupId, featureId) {
    return this.fetch(
      `feature-override-groups/${groupId}/features/${featureId}`,
      'DELETE',
    );
  }

  updateBillingFeatureOverrideGroup(billingId, groupId) {
    return this.fetch(`account/billing/feature-overrides`, 'POST', {
      body: { featureOverrideGroupId: groupId, billingId },
    });
  }

  /* get all unique features available */
  getUniqueFeaturesList() {
    return this.fetch(`all-features`);
  }

  /* get all current features of user */
  getUserFeaturesList(userId) {
    return this.fetch(`user/${userId}/features`);
  }

  /* Delete user feature */
  deleteUserFeature({ featureName, userId }) {
    return this.fetch(`user/${userId}/features/${featureName}`, 'DELETE');
  }

  /* Add new feature to user */
  addUserFeature({ userId, featureName }) {
    return this.fetch(`user/${userId}/features/${featureName}`, 'PUT');
  }
}

export const client = new CoreService();
