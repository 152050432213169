import { createRouteBundle } from 'redux-bundler';

import loadable from 'utils/loadable';
const Home = loadable(() => import('pages/Home'));
const Login = loadable(() => import('pages/Login'));
const ListAccount = loadable(() => import('pages/ListAccount'));
const AccountDetail = loadable(() => import('pages/AccountDetail'));
const ListUser = loadable(() => import('pages/ListUser'));
const UserDetail = loadable(() => import('pages/UserDetail'));
const ListBase = loadable(() => import('pages/ListBase'));
const BaseDetail = loadable(() => import('pages/BaseDetail'));
const NotFound = loadable(() => import('pages/NotFound'));
const ListFeatureOverrideGroup = loadable(() =>
  import('pages/ListOverrideFeatureGroup'),
);
const OverrideFeatureGroupDetail = loadable(() =>
  import('pages/OverrideFeatureGroupDetail'),
);

export default createRouteBundle({
  '/': {
    name: 'Home',
    component: Home,
    title: "Base's Customer Support",
  },
  '/login': {
    name: 'Login',
    component: Login,
    title: 'Login',
  },
  '/accounts': {
    name: 'Accounts',
    component: ListAccount,
    title: 'Accounts List',
  },
  '/accounts/:accountId': {
    name: 'AccountDetail',
    component: AccountDetail,
    title: 'Account',
  },
  '/users': {
    name: 'Users',
    component: ListUser,
    title: 'Users List',
  },
  '/users/:userId': {
    name: 'UserDetail',
    component: UserDetail,
    title: 'User',
  },
  '/bases': {
    name: 'Bases',
    component: ListBase,
    title: 'Workspaces List',
  },
  '/bases/:baseId': {
    name: 'BaseDetail',
    component: BaseDetail,
    title: 'Workspace',
  },
  '/feature-override-groups': {
    name: 'ListFeatureOverrideGroup',
    component: ListFeatureOverrideGroup,
    title: 'Feature override groups',
  },
  '/feature-override-groups/:id': {
    name: 'OverrideFeatureGroupDetail',
    component: OverrideFeatureGroupDetail,
    title: 'Feature override groups',
  },
  '*': {
    name: 'NotFound',
    component: NotFound,
    title: '404 Not Found',
  },
});
