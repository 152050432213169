import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'userDetail',
  getPromise: ({ coreClient, store }) => {
    const userId = store.selectRouteParams().userId;
    return coreClient.fetchUserDetail(userId);
  },
  actionBaseType: 'USER_DETAIL',
  persist: false,
});

bundle.reactShouldFetchPolicy = createSelector(
  'selectUserDetailIsLoading',
  'selectPathname',
  'selectRouteParams',
  'selectUserDetail',
  'selectUserDetailFailedPermanently',
  'selectUserDetailShouldUpdate',
  (isLoading, pathname, routeParams, userDetail, failed, shouldUpdate) => {
    const actionCreator = { actionCreator: 'doFetchUserDetail' };

    if (pathname.includes('/users/') && routeParams.userId) {
      /* first time fetch and check if data should be fetched in this route */
      if (shouldUpdate) {
        return actionCreator;
      }
      /* second time onwards, when changing route */
      if (
        !failed &&
        userDetail &&
        userDetail.user.id.toString() !== routeParams.userId.toString() &&
        !isLoading
      ) {
        return actionCreator;
      }
    }
    return false;
  },
);

bundle.doUpdateUserDetail = (userId, data) => ({
  dispatch,
  store,
  coreClient,
}) => {
  return coreClient.updateUserDetail(userId, data).then(resp => {
    store.doFetchUserDetail();
    return resp;
  });
};
bundle.doUnlinkLinkedAccount = (userId, linkedAccountId) => async ({
  dispatch,
  store,
  coreClient,
}) => {
  await coreClient.unlinkLinkedAccountByUserId(userId, linkedAccountId);
  return store.doFetchUserDetail();
};

bundle.doDeleteAccountFromUser = (userId, accountId) => async ({
  store,
  coreClient,
}) => {
  const resp = await coreClient.deleteUserFromAccount(accountId, userId);
  store.doFetchUserDetail();
  return resp;
};

export default bundle;
