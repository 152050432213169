import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'accountInvitations',
  actionBaseType: 'ACCOUNT_INVITATIONS',
  getPromise: ({ coreClient, store }) => {
    const accountId = store.selectRouteParams().accountId;
    return coreClient.fetchAccountInvitations(accountId);
  },
  persist: false,
});

bundle.reactShouldFetchAccountInvitations = createSelector(
  'selectPathname',
  'selectRouteParams',
  'selectAccountInvitationsIsLoading',
  'selectAccountInvitationsShouldUpdate',
  'selectAccount',
  (pathname, routeParams, isLoading, shouldUpdate, account) => {
    if (isLoading || !pathname.includes('/accounts/')) {
      return false;
    }
    if (account && account.id === parseInt(routeParams.accountId)) {
      return false;
    }
    return { actionCreator: 'doFetchAccountInvitations' };
  },
);

bundle.doCreateAccountInvitation = (accountId, data) => ({
  dispatch,
  store,
  coreClient,
}) => {
  return coreClient.createAccountInvitation(accountId, data).then(resp => {
    store.doFetchAccountInvitations();
    return resp;
  });
};

bundle.doDeleteAccountInvitation = (accountId, id) => ({
  dispatch,
  store,
  coreClient,
}) => {
  return coreClient.deleteAccountInvitation(accountId, id).then(resp => {
    store.doFetchAccountInvitations();
    return undefined;
  });
};

export default bundle;
