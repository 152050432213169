import dlv from 'dlv';
import { createAsyncActions } from './utils/asyncActions';
import cache from 'utils/cache';
import { AuthSvc, initAuthSingleton } from 'services/auth';

const AuthSingleton = new AuthSvc({ storagePrefix: 'base_' });
initAuthSingleton(AuthSingleton);
const initialState = { success: false, error: '' };
const actions = createAsyncActions('AUTH');

export default {
  name: 'logout',
  getReducer: () => {
    return (state = initialState, { type, payload }) => {
      if (type === actions.STARTED) {
        return Object.assign({}, state, payload);
      }
      if (type === actions.FINISHED) {
        return Object.assign({}, state, { success: true, error: '' });
      }
      if (type === actions.FAILED) {
        return Object.assign({}, state, {
          success: false,
          error: payload,
        });
      }
      return state;
    };
  },
  doLogout: () => async ({ dispatch, store, coreClient }) => {
    try {
      dispatch({ type: actions.FINISHED });
    } catch (err) {
      dispatch({
        type: actions.FAILED,
        payload: dlv(err, 'response.data.error', 'Something went wrong'),
      });
    } finally {
      await AuthSingleton.signOut();
      await cache.clear();
      window.location = '/login';
    }
  },
};
