import { client as coreClient } from 'services/core';
import { client as ssoClient } from 'services/sso';
import { client as SCIMClient } from 'services/SCIMService';

export default {
  name: 'extra-args',
  // note that the store gets passed in here:
  getExtraArgs: store => {
    return {
      coreClient,
      ssoClient,
      // A bundle to inject additional dependencies to the store. Typically
      // you would import api clients and provide them here.
      // If your API requires an authentication token or whatnot
      // here would be a great place to select it from your store
      // and pass it along with the fetch. Then none of your individual
      // action creators need to worry about this.
    };
  },
  init: store => {
    coreClient.setToken(store.selectAccessToken());
    coreClient.onRequestError(() => store.doUnsetUser());
    coreClient.onStatus401(async () => {
      try {
        const token = await store.doRefreshToken();
        return token;
      } catch (err) {
        return Promise.reject(err);
      }
    });
    ssoClient.setToken(store.selectAccessToken());
    ssoClient.onRequestError(() => store.doUnsetUser());
    ssoClient.onStatus401(async () => {
      try {
        const token = await store.doRefreshToken();
        return token;
      } catch (err) {
        return Promise.reject(err);
      }
    });
    SCIMClient.setToken(store.selectAccessToken());
    SCIMClient.onRequestError(() => store.doUnsetUser());
    SCIMClient.onStatus401(() => store.doRefreshToken());
    // subscribes to token updates
    store.subscribeToSelectors(['selectAccessToken'], ({ userToken }) => {
      coreClient.setToken(userToken);
      ssoClient.setToken(userToken);
      SCIMClient.setToken(userToken);
    });
  },
};
