import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import debounce from 'utils/debounce';
import dlv from 'dlv';
import {
  Layout,
  Avatar,
  Dropdown,
  Menu,
  AutoComplete,
  Icon,
  Input,
  Button,
} from 'antd';
import { client as coreClient } from 'services/core';

import AddAccountForm from './AddAccountForm';

const { Header } = Layout;
const { Option, OptGroup } = AutoComplete;

const options = result => {
  if (!result) {
    return [];
  }
  return Object.entries(result).reduce((acc, [key, { title, value }]) => {
    const newAcc = [...acc];
    if (
      /* if empty value, skip */
      value.length > 0 &&
      /* if only has admin as result, don't create OptGroup */
      dlv(value, '[0].id', null) !== 1
    ) {
      newAcc.push(
        <OptGroup key={title} label={renderTitle(title)}>
          {value.map(opt => {
            const optionsList = [];
            /* don't show admin account/user */
            if (opt.id !== 1) {
              optionsList.push(
                <Option key={opt.id} value={`${title}_${opt.id}`}>
                  {`${opt.name} - ${opt.id}`}
                </Option>,
              );
            }
            return optionsList;
          })}
        </OptGroup>,
      );
    }
    return newAcc;
  }, []);
};

function renderTitle(title) {
  return <span>{title}</span>;
}

const TopHeader = ({ doLogout, doUpdateUrl }) => {
  const [searchedValue, setSearchedValue] = useState('');
  const [data, setData] = useState({});

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  const searchHandler = React.useCallback(
    async value => {
      try {
        if (!value) {
          setData({});
          setSearchedValue('');
        }

        const rsp = await coreClient.searchAll(value);
        setData(rsp);
        setSearchedValue(value);
      } catch (err) {
        console.log(err);
      }
    },
    [setData, setSearchedValue],
  );

  const selectHandler = React.useCallback(
    (value, option) => {
      const arrVal = value.split('_');
      doUpdateUrl(`/${arrVal[0].toLowerCase() + 's'}/${arrVal[1]}`);
    },
    [doUpdateUrl],
  );

  const createAccount = e => {
    setIsAddAccountModalOpen(true);
  };

  return (
    <Header className="px-4 bg-white flex items-center">
      <>
        <Icon type="search" />
        <AutoComplete
          dropdownMatchSelectWidth={false}
          style={{ width: '400px' }}
          dataSource={options(data)}
          placeholder="Search by ID, Email or Name"
          optionLabelProp="value"
          onSelect={(value, option) => selectHandler(value, option)}
          onSearch={debounce(value => searchHandler(value), 500)}
          onFocus={() => searchHandler(searchedValue)}
        >
          <Input className="shadow-none outline-none border-transparent" />
        </AutoComplete>
      </>

      <Button
        type="primary"
        style={{
          marginRight: '20px',
          marginLeft: 'auto',
        }}
        onClick={createAccount}
      >
        Add Account
      </Button>
      <Dropdown
        overlay={() => (
          <Menu>
            <Menu.Item key="0" onClick={doLogout}>
              Logout
            </Menu.Item>
          </Menu>
        )}
      >
        <span className="flex items-center cursor-pointer">
          <span className="inline-block mr-3">
            Hi, <b>Admin Dashboard</b>
          </span>
          <Avatar icon="user" />
        </span>
      </Dropdown>

      <AddAccountForm
        visible={isAddAccountModalOpen}
        onClose={() => setIsAddAccountModalOpen(false)}
      />
    </Header>
  );
};

export default connect('doLogout', 'doUpdateUrl', TopHeader);
