import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'accountSaml',
  getPromise: async ({ dispatch, ssoClient, store }) => {
    try {
      const accountId = store.selectRouteParams().accountId;
      if (!accountId) {
        return {};
      }
      const { integration } = await ssoClient.fetchAccountSaml(accountId);
      return integration;
    } catch (err) {
      dispatch({ type: 'ACCOUNT_SAML_FETCH_FAILED', err });
      return {};
    }
  },
  actionBaseType: 'ACCOUNT_SAML',
  persist: false,
});

bundle.reactShouldFetchAccountSaml = createSelector(
  'selectRouteParams',
  'selectAccount',
  'selectAccountSamlIsLoading',
  'selectAccountSamlShouldUpdate',
  (routeParams, account, isLoading, shouldUpdate) => {
    if (!account || !routeParams || !routeParams.accountId || isLoading) {
      return false;
    }

    if (
      !shouldUpdate &&
      routeParams &&
      parseInt(routeParams.accountId) === account.id
    ) {
      return false;
    }

    return { actionCreator: 'doFetchAccountSaml' };
  },
);

/* update metadata for a saml integration */
bundle.doUpdateAccountSamlMetadata = (
  accountId,
  metadataUrl,
  metadataXml,
) => async ({ dispatch, store, ssoClient }) => {
  try {
    await ssoClient.updateAccountSaml(accountId, metadataUrl, metadataXml);
    return store.doFetchAccountSaml();
  } catch (err) {
    dispatch({ type: 'ACCOUNT_SAML_FETCH_FAILED', err });
  }
};

/* update enabled/disabled for a saml integration */
bundle.doUpdateAccountSamlEnabled = (accountId, enabled) => async ({
  dispatch,
  store,
  ssoClient,
}) => {
  try {
    await ssoClient.enableAccountSaml(accountId, enabled);
    return store.doFetchAccountSaml();
  } catch (err) {
    dispatch({ type: 'ACCOUNT_SAML_FETCH_FAILED', err });
  }
};

/* delete saml integration */
bundle.doDeleteAccountSaml = accountId => async ({
  dispatch,
  store,
  ssoClient,
}) => {
  try {
    await ssoClient.deleteAccountSaml(accountId);
    await store.doFetchAccountSaml();
    return store.doFetchAccount();
  } catch (err) {
    dispatch({ type: 'ACCOUNT_SAML_FETCH_FAILED', err });
  }
};

export default bundle;
