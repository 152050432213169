import { createSelector } from 'redux-bundler';
import { isPositive } from 'utils/number';

const publicUrls = ['/', '/login'];

export default {
  name: 'redirects',
  reactRedirects: createSelector(
    'selectPathname',
    'selectIsLoggedIn',
    'selectQueryObject',
    (pathname, isLoggedIn, queryObject) => {
      if (isLoggedIn && publicUrls.includes(pathname)) {
        return { actionCreator: 'doUpdateUrl', args: ['/accounts'] };
      }
      if (!isLoggedIn && !pathname.includes('/login')) {
        return { actionCreator: 'doUpdateUrl', args: ['/login'] };
      }

      if (['/accounts', '/users', '/bases'].includes(pathname)) {
        const { page } = queryObject;
        if (!isPositive(page)) {
          return {
            actionCreator: 'doUpdateQuery',
            args: [{ page: 1 }],
          };
        }
      }

      return false;
    },
  ),
  reactTrailingSlash: createSelector('selectPathname', pathname => {
    // remove trailing slash
    if (pathname !== '/' && pathname.endsWith('/')) {
      return { actionCreator: 'doReplaceUrl', args: [pathname.slice(0, -1)] };
    }
  }),
};
