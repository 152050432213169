import {
  composeBundles,
  createUrlBundle,
  createCacheBundle,
} from 'redux-bundler';

import cache from 'utils/cache';
import extraArgs from './extra-args';
import routes from './routes';
import redirect from './redirect';
import allAccounts from './all-accounts';
import allUsers from './all-users';
import allBases from './all-bases';
import account from './account';
import userDetail from './user-detail';
import accountInvitations from './account-invitations';
import auth from './auth';
import logout from './logout';
import user from './user';
import userUnlink from './user-unlink';
import accountBilling from './account-billing';
import baseDetail from './base-detail';
import usersOfAccount from './users-of-account';
import totals from './totals';
import pageTitle from './page-title';
import accountSaml from './account-saml';

export default composeBundles(
  createUrlBundle(),
  routes,
  createCacheBundle(cache.set),
  extraArgs,
  redirect,
  user,
  auth,
  logout,
  allAccounts,
  allUsers,
  allBases,
  totals,
  userDetail,
  userUnlink,
  accountInvitations,
  accountBilling,
  baseDetail,
  usersOfAccount,
  account,
  pageTitle,
  accountSaml,
);
