class SCIMService {
  /**
   * @type {string}
   */
  #token;
  setToken = value => (this.#token = value);

  #handleRequestError = () => {};

  onRequestError(cb) {
    this.#handleRequestError = cb;
  }

  /**
   * @returns {Promise<string>}
   */
  #handleStatus401 = async () => '';

  /**
   *
   * @param {() => Promise<string>} cb
   */
  onStatus401(cb) {
    this.#handleStatus401 = cb;
  }

  /**
   * @param {number}accountId
   * @returns {Promise<{createdAt: string, accountId: string, id: string, updatedAt: string}>}
   */
  async fetchIntegration(accountId) {
    const url = `${process.env.REACT_APP_API_HOST}/internal/scim/account/${accountId}/integration`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.#token}`,
      },
    });

    if (response.status === 401) {
      await this.#handleStatus401();
      return this.fetchIntegration(accountId);
    }

    if (!response.ok) {
      return null;
    }

    return response.json();
  }

  /**
   * @param {number}accountId
   * @param {string}password
   * @returns {Promise<{createdAt: string, accountId: string, id: string, updatedAt: string}>}
   */
  async createIntegration(accountId, password) {
    const url = `${process.env.REACT_APP_API_HOST}/internal/scim/account/${accountId}/integration`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.#token}`,
      },
      method: 'POST',
      body: JSON.stringify({ password }),
    });

    if (response.status === 401) {
      await this.#handleStatus401();
      return this.createIntegration(accountId, password);
    }

    if (!response.ok) {
      return null;
    }

    return response.json();
  }

  /**
   * @param {number}accountId
   * @param {string}password
   * @returns {Promise<void>}
   */
  async updateIntegration(accountId, password) {
    const url = `${process.env.REACT_APP_API_HOST}/internal/scim/account/${accountId}/integration`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.#token}`,
      },
      method: 'PATCH',
      body: JSON.stringify({ password }),
    });

    if (response.status === 401) {
      await this.#handleStatus401();
      return this.updateIntegration(accountId, password);
    }

    if (!response.ok) {
      throw new Error('Could not update the integration');
    }
  }

  /**
   * @param {number}accountId
   * @returns {Promise<void>}
   */
  async deleteIntegration(accountId) {
    const url = `${process.env.REACT_APP_API_HOST}/internal/scim/account/${accountId}/integration`;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.#token}`,
      },
      method: 'DELETE',
    });

    if (response.status === 401) {
      await this.#handleStatus401();
      return this.deleteIntegration(accountId);
    }

    if (!response.ok) {
      throw new Error('Could not delete the integration');
    }
  }
}

export const client = new SCIMService();
