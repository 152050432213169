import { createSelector, createAsyncResourceBundle } from 'redux-bundler';
import dlv from 'dlv';
import { compareSortField } from 'utils/misc';

const bundle = createAsyncResourceBundle({
  name: 'allUsers',
  actionBaseType: 'ALL_USERS',
  getPromise: ({ coreClient, store }) => {
    const { page, ...rest } = store.selectQueryObject();
    return coreClient.listUser({ ...rest, page, limit: 10 });
  },
  persist: false,
});

bundle.reactShouldFetchAllUsers = createSelector(
  'selectAllUsers',
  'selectAllUsersShouldUpdate',
  'selectAllUsersIsLoading',
  'selectPathname',
  'selectQueryObject',
  (allUsers, shouldUpdate, isLoading, pathname, queryObject) => {
    const actionObj = { actionCreator: 'doFetchAllUsers' };
    const { page: pageQueryString, ...rest } = queryObject;
    const pageQuery = parseInt(pageQueryString, 10);
    const page = dlv(allUsers, 'page', 1);
    const sort = dlv(allUsers, 'sort', {});

    /* directly go into route */
    if (!isLoading && shouldUpdate) {
      /* fetch in users page */
      if (pathname === '/users') {
        return actionObj;
      }
    }

    /* navigating to this route */
    if (!isLoading && !shouldUpdate) {
      if (
        (pageQuery !== page || compareSortField(sort, rest)) &&
        pathname === '/users'
      ) {
        return actionObj;
      }
    }
    return false;
  },
);

bundle.doFetchUsers = () => ({ coreClient, dispatch }) => {
  return coreClient.listUser({ page: 1, limit: -1 }).then(users => {
    dispatch({ type: 'ALL_USERS_FETCH_FINISHED', payload: users });
    return users;
  });
};

export default bundle;
