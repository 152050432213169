import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'totals',
  actionBaseType: 'TOTALS',
  getPromise: ({ coreClient }) => coreClient.getTotal(),
  persist: false,
  staleAfter: 600000,
});

bundle.reactShouldFetchTotals = createSelector(
  'selectTotalsShouldUpdate',
  'selectTotalsIsLoading',
  'selectPathname',
  'selectTotalsIsStale',
  (shouldUpdate, isLoading, pathname, isStale) => {
    const actionObj = { actionCreator: 'doFetchTotals' };
    if (!isLoading && pathname !== '/login') {
      if (shouldUpdate) {
        return actionObj;
      }
      /* if (isStale) {return actionObj;} */
    }
    return false;
  },
);
export default bundle;
