import dlv from 'dlv';
import ky from 'ky';
import queryString from 'query-string';

const convertToJson = async resp => {
  try {
    const result = await resp.json();
    return result;
  } catch (err) {
    return resp;
  }
};

class SSOService {
  setToken = value => (this.token = value);
  getToken = () => this.token || '';

  onRequestError = cb => {
    typeof cb === 'function'
      ? (this.handleRequestError = cb)
      : (this.handleRequestError = () => {});
  };

  onStatus401 = cb => {
    typeof cb === 'function'
      ? (this.handleStatus401 = cb)
      : (this.handleStatus401 = () => {});
  };

  api = ky.extend({
    prefixUrl: process.env.REACT_APP_API_HOST + '/dashboard/',
  });

  // TODO: handle request errors!

  fetch = async (path = '', method = 'GET', { body, params, ...opt } = {}) => {
    try {
      const qs = queryString.stringify(params);
      const resp = await this.api(`${path}${qs ? '?' + qs : ''}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.getToken()}`,
        },
        method,
        json: body,
        ...opt,
      });
      /* try convert to json */
      const result = await convertToJson(resp);
      return result;
      /* end try */
    } catch (err) {
      if (err.name === 'TypeError' && err.message === 'Failed to fetch') {
        console.warn('failed to get proper response from api server, logout');
        this.handleRequestError();
      }
      const statusCode = dlv(err, 'response.status');
      if (statusCode === 401) {
        /* try to refresh token and request again */
        await this.handleStatus401();
        const qs = queryString.stringify(params);
        const resp = await this.api(`${path}${qs ? '?' + qs : ''}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.getToken()}`,
          },
          method,
          json: body,
          ...opt,
        });
        const result = await convertToJson(resp);
        return result;
      }
      return Promise.reject(err);
    }
  };

  async fetchAccountSaml(accountId) {
    try {
      return await this.fetch(`account/${accountId}/saml`);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateAccountSaml(accountId, metadataUrl, metadataXml) {
    const body = {};
    if (metadataUrl) body.metadataUrl = metadataUrl;
    if (metadataXml) {
      body.metadataXml = Buffer.from(metadataXml).toString('base64');
    }

    try {
      return await this.fetch(`account/${accountId}/saml`, 'POST', {
        body,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async enableAccountSaml(accountId, enabled) {
    try {
      return await this.fetch(`account/${accountId}/saml/enable`, 'POST', {
        body: {
          enabled,
        },
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async deleteAccountSaml(accountId) {
    try {
      return await this.fetch(`account/${accountId}/saml`, 'DELETE');
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default SSOService;

export const client = new SSOService();
