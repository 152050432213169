const bundle = {
  name: 'pageTitle',
  reducer: (state = "Base's Customer Support", { type, title }) => {
    switch (type) {
      case 'SET_TITLE':
        return title;
      default:
        return state;
    }
  },
  doUpdateTitle: title => {
    return {
      type: 'SET_TITLE',
      title,
    };
  },
  selectPageTitle: state => state.pageTitle,
};

export default bundle;
