import { createSelector, createAsyncResourceBundle } from 'redux-bundler';
import dlv from 'dlv';
import { compareSortField } from 'utils/misc';

const bundle = createAsyncResourceBundle({
  name: 'allBases',
  actionBaseType: 'ALL_BASES',
  getPromise: ({ coreClient, store }) => {
    const { page, ...rest } = store.selectQueryObject();
    return coreClient.listBase({ ...rest, page, limit: 10 });
  },
  persist: false,
});

bundle.reactShouldFetchAllBases = createSelector(
  'selectAllBases',
  'selectAllBasesShouldUpdate',
  'selectAllBasesIsLoading',
  'selectPathname',
  'selectQueryObject',
  (allBases, shouldUpdate, isLoading, pathname, queryObject) => {
    const actionObj = { actionCreator: 'doFetchAllBases' };

    const { page: pageQueryString, ...rest } = queryObject;
    const pageQuery = parseInt(pageQueryString, 10);

    const page = dlv(allBases, 'page', 1);
    const sort = dlv(allBases, 'sort', {});

    /* go directly into this route */
    if (!isLoading && shouldUpdate) {
      if (pathname === '/bases') {
        return actionObj;
      }
    }
    /* navigating to here */
    if (!isLoading && !shouldUpdate) {
      if (
        pathname === '/bases' &&
        (pageQuery !== page || compareSortField(sort, rest))
      ) {
        return actionObj;
      }
    }
    return false;
  },
);

export default bundle;
