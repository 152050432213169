import { createAsyncResourceBundle, createSelector } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'account',
  getPromise: async ({ coreClient, store }) => {
    try {
      const accountId = store.selectRouteParams().accountId;
      return await coreClient.fetchAccountDetail(accountId);
    } catch (err) {
      return Promise.reject(new Error('Failed to fetch'));
    }
  },
  actionBaseType: 'ACCOUNT',
  persist: false,
});

bundle.reactShouldFetchAccount = createSelector(
  'selectAccountIsLoading',
  'selectPathname',
  'selectRouteParams',
  'selectAccount',
  'selectAccountShouldUpdate',
  (isLoading, pathname, routeParams, account, shouldUpdate) => {
    if (isLoading || !pathname.includes('/accounts/')) {
      return false;
    }
    if (account && account.id === parseInt(routeParams.accountId)) {
      return false;
    }
    return { actionCreator: 'doFetchAccount' };
  },
);

bundle.doUpdateAccount = (accountId, data) => ({ store, coreClient }) => {
  return coreClient.updateAccount(accountId, data).then(resp => {
    store.doFetchAccount();
    return resp;
  });
};

bundle.doUpdateAccountInfo = (accountId, data) => ({ store, coreClient }) => {
  return coreClient.updateAccountInfo(accountId, data).then(resp => {
    store.doFetchAccount();
    return resp;
  });
};

bundle.doDeleteUserFromAccount = (accountId, userId) => ({
  store,
  coreClient,
}) => {
  return coreClient.deleteUserFromAccount(accountId, userId).then(resp => {
    store.doFetchAccount();
    return resp;
  });
};

bundle.doDeleteAccountBase = (accountId, baseId) => ({ store, coreClient }) => {
  return coreClient.deleteAccountBase(accountId, baseId).then(resp => {
    store.doFetchAccount();
    return resp;
  });
};

bundle.doDeleteAccount = accountId => ({ store, coreClient }) => {
  return coreClient.deleteAccount(accountId).then(resp => {
    store.doFetchAllAccounts();
    return resp;
  });
};

bundle.doAddAccount = (email, firstName, lastName, password) => ({
  coreClient,
}) => coreClient.addAccount(email, firstName, lastName, password);

bundle.doCreateAccountBase = (accountId, data) => ({ store, coreClient }) => {
  return coreClient.createAccountBase(accountId, data).then(resp => {
    store.doFetchAccount();
    return resp;
  });
};

bundle.doTransferBase = (
  accountId,
  baseId,
  newAccountId,
  newPrimaryEmail,
  newUserId,
  securityGroup,
) => async ({ coreClient }) => {
  try {
    return await coreClient.transferBase(
      accountId,
      baseId,
      newAccountId,
      newPrimaryEmail,
      newUserId,
      securityGroup,
    );
  } catch (err) {
    return Promise.reject(new Error("Couldn't transfer base"));
  }
};

bundle.doFetchAccountById = accountId => ({ coreClient }) =>
  coreClient.fetchAccountDetail(accountId);

bundle.doAddUserToAccount = (accountId, userId, securityGroup) => async ({
  coreClient,
}) => {
  try {
    return await coreClient.addUserToAccount(accountId, userId, securityGroup);
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doDeleteUser = (userId, newAccountId, newUserId) => ({ coreClient }) =>
  coreClient.deleteUser(userId, newAccountId, newUserId);

bundle.doSwitchUserPermission = (
  accountId,
  userId,
  securityGroup,
  currentSecurityGroup,
) => async ({ coreClient, store }) => {
  try {
    const result = await coreClient.switchUserPermission(
      accountId,
      userId,
      securityGroup,
      currentSecurityGroup,
    );
    await store.doFetchAccount();
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doUpdateAccountUserLoginType = (accountId, userId, loginType) => async ({
  coreClient,
  store,
}) => {
  try {
    const result = await coreClient.updateUserAccountLoginType(
      accountId,
      userId,
      loginType,
    );
    await store.doFetchAccount();
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default bundle;
