import { createSelector, createAsyncResourceBundle } from 'redux-bundler';

const bundle = createAsyncResourceBundle({
  name: 'baseDetail',
  getPromise: ({ coreClient, store }) => {
    const baseId = store.selectRouteParams().baseId;
    return coreClient.fetchBaseDetail(baseId);
  },
  actionBaseType: 'BASE_DETAIL',
  persist: false,
});

bundle.reactShouldFetchBaseDetail = createSelector(
  'selectBaseDetailIsLoading',
  'selectPathname',
  'selectRouteParams',
  'selectBaseDetail',
  'selectBaseDetailFailedPermanently',
  'selectBaseDetailShouldUpdate',
  (isLoading, pathname, routeParams, baseDetail, failed, shouldUpdate) => {
    const actionCreator = { actionCreator: 'doFetchBaseDetail' };
    if (pathname.includes('/bases/') && routeParams.baseId) {
      /* first time fetch and check if data should be fetched in this route */
      if (shouldUpdate) {
        return actionCreator;
      }
      /* second time onwards, when changing route */
      if (
        !failed &&
        baseDetail &&
        baseDetail.id.toString() !== routeParams.baseId.toString() &&
        !isLoading
      ) {
        return actionCreator;
      }
    }
    return false;
  },
);

bundle.doUpdateBaseDetail = (
  baseId,
  baseName,
  firstName,
  lastName,
  primaryEmail,
) => async ({ coreClient }) => {
  try {
    const updateResult = await coreClient.updateBaseDetail(
      baseId,
      baseName,
      firstName,
      lastName,
      primaryEmail,
    );
    return updateResult;
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doAddUserToBase = (
  baseId,
  userId,
  securityGroup,
  inviterEmail,
) => async ({ coreClient, store }) => {
  try {
    const result = await coreClient.addUserToBase(
      baseId,
      userId,
      securityGroup,
      inviterEmail,
    );
    store.doFetchBaseDetail();
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doInviteUserToBase = (
  baseId,
  accountId,
  userEmail,
  securityGroup,
  inviterEmail,
) => async ({ coreClient, store }) => {
  try {
    const result = await coreClient.inviteUserBase(
      baseId,
      accountId,
      userEmail,
      securityGroup,
      inviterEmail,
    );
    store.doFetchBaseDetail();
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doUpdateBaseUserPermission = ({
  userId,
  accountId,
  currentPermission,
  newPermission,
}) => async ({ coreClient, store }) => {
  try {
    const baseId = store.selectRouteParams().baseId;

    await coreClient.updateBaseUserPermission({
      userId,
      accountId,
      baseId,
      currentPermission,
      newPermission,
    });
    await store.doFetchBaseDetail();
  } catch (err) {
    return Promise.reject(err);
  }
};

bundle.doDeleteUserFromBaseDetail = (baseId, userId) => async ({
  coreClient,
  store,
}) => {
  try {
    await coreClient.deleteUserFromBaseDetail(baseId, userId);
    store.doFetchBaseDetail();
  } catch (err) {
    return Promise.reject(err);
  }
};
// Handle disable workspace by account inside workspace detail
bundle.doDisableBase = (accountId, baseId) => ({ store, coreClient }) => {
  return coreClient.disableAccountBase(accountId, baseId).then(resp => {
    store.doFetchBaseDetail();
    store.doFetchAllBases();
    return resp;
  });
};
// Handle enable workspace
bundle.doEnableBase = (accountId, baseId) => ({ store, coreClient }) => {
  return coreClient.enableAccountBase(accountId, baseId).then(resp => {
    store.doFetchBaseDetail();
    store.doFetchAllBases();
    return resp;
  });
};

bundle.doDeleteBase = (accountId, baseId) => ({ store, coreClient }) => {
  return coreClient.deleteAccountBase(accountId, baseId).then(resp => {
    store.doFetchBaseDetail();
    store.doFetchAllBases();
    return resp;
  });
};
export default bundle;
